import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nested-form"
export default class extends Controller {
  static targets = ["add_item", "template"]
  
  connect() {
  }
  
  add(event) {
    event.preventDefault
    let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, Math.floor(Math.random() * 20))
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
  }
  
  removeAssociation(event) {
    event.preventDefault
    let item = event.target.closest('.nested-fields')
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
  }
  
}
