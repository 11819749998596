import { Controller } from "@hotwired/stimulus"
import { clearSection } from "../field_clear"

// Connects to data-controller="form-toggle"
export default class extends Controller {
  connect() {
    const element = this.element
    const container = element.closest('.toggle-container')

    this.hideAll(container)
    this.activate(container, element.value.toString())
  }

  switch() {
    const element = event.target
    const container = element.closest('.toggle-container')

    this.clearAll(container)
    this.hideAll(container)
    this.activate(container, element.value)
  }

  activate(container, value) {
    let element = container.querySelector(`[data-handle="${value}"]`)
    if (element) { element.classList.add('is-active') }
  }


  hideAll(container)  {
    const sections = container.querySelectorAll('.recurrence-option')

    sections.forEach(function(section) {
      section.classList.remove('is-active')
    })
  }

  clearAll(container) {
    const options = container.querySelectorAll('.recurrence-option')

    options.forEach((option) => {
      clearSection(option)
    })

  }

}