import { Controller } from "@hotwired/stimulus"
import { clearSection } from "../field_clear"

// Connects to data-controller="alternates"
export default class extends Controller {

  connect() {
  }

  switch(event) {
    const element = event.target
    const container = element.closest('.alternatives')
    let target = element.dataset.alternate
    const target_elements = container.querySelectorAll(`[data-handle=${target}]`) 

    target_elements.forEach((targetElement) => {
      // console.log(targetElement.parentElement)
      clearSection(targetElement.parentElement)
    })
  }
}
