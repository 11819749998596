import { Controller } from "@hotwired/stimulus"
import LazyLoader from 'stimulus-lazy-loader';

// Connects to data-controller="lazy-load"
export default class extends Controller {
  
  static targets = ["image"]
  
  connect() {
  }
}
