import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {

  connect() {
    // ok so first we have to determine there should be tabs

    let container = this.element
    let panels = container.querySelectorAll('.panel')

    let handle = container.dataset.handle

    container.classList.add('panels')
    
    // This whole section checks to make sure that a tab-conatiner element doesnt already exist.
    let prev = container.previousSibling // check to make sure the previous element isn't a tab container already constructed and not remvoed.
    
    let halt;
    
    if ( prev != null && prev.classList.contains('tab-container') ) {
      halt = true
    }

    if (panels.length > 1 && halt == null ) {
      // ok looks like we have more than one panel, the first step is to build a tab container
      const tabContainer = this.constructTabContainer(handle)

      // then we gotta loop through all of the panels and create the appropriate tab.
      for (const panel of panels) {

        let properties = {
          handle: panel.dataset.handle,
          name: panel.dataset.name,
          icon: panel.dataset.icon,
          count: panel.dataset.count
        }

        const tab = this.constructTab(properties)
        tabContainer.querySelector('.tabs').appendChild(tab)

        // ok now lets pre-pend the tabs element to this one on the DOM stack

        const parent = container.parentElement
        parent.insertBefore(tabContainer, container)
      }
    }
    else
    {
      // just make the first panel active and leave as is.
      panels[0].classList.add('is-active')
    }
  }

  constructTabContainer(handle) {
    const container = document.createElement('div')
    container.classList.add('tab-container')
    

    // const element = document.createElement('div')
    

    const list = document.createElement('ul')
    list.classList.add('tabs')
    list.dataset.controller = 'tabs'
    list.dataset.handle = handle

    // element.appendChild(list)
    container.appendChild(list)

    return container
  }

  constructTab(properties) {
    const li = document.createElement('li')

    const a = document.createElement('a')
    a.classList.add('tab')
    a.dataset.action = 'click->tabs#setTab'
    a.dataset.panel = properties.handle
    
    // ok I'm going to expect an SVG string as a data attribute. This prolly isn't the best way to do it, but it's all I know how to do.
    
    if (typeof properties.icon != 'undefined') {
      const icon = new DOMParser().parseFromString(properties.icon, 'text/xml')
      a.appendChild(icon.firstChild)
    }
    
    const span = document.createElement('span')
    span.innerText = properties.name

    if ((typeof properties.count != 'undefined') && parseInt(properties.count) > 0) {
      const count = document.createElement('div')
      count.innerHTML = properties.count
      count.classList.add('counter')
      a.appendChild(count)
    } 

    a.appendChild(span)
    li.appendChild(a)

    return li
  }

  constructIcon(string) {
    let raw = new DOMParser().parseFromString(string, 'text/xml')
    return raw.firstChild
  }

}
