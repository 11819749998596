import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";

// Connects to data-controller="date-time-picker"
export default class extends Controller {
  connect() {
    let element = this.element
    flatpickr(element, {
      enableTime:      true,
      altInput:        true,
      altFormat:       "F j, Y at h:i K",
      dateFormat:      "Y-m-d H:i",
      defaultHour:     23,
      defaultMinute:   59,
      minuteIncrement: 1
    })
  }
}
