import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";

// Connects to data-controller="date-picker"
export default class extends Controller {
  connect() {
    let element = this.element
    flatpickr(element, {
      altInput:        true,
      altFormat:       "F j, Y",
      dateFormat:      "Y-m-d"
    })
  }
}
