import { Controller } from "@hotwired/stimulus"
import mapboxgl from 'mapbox-gl'
import Rails from '@rails/ujs'

// Connects to data-controller="dashboard-location-map"
export default class extends Controller {
  connect() {
    this.map = null
    this.initMapbox(this.element)
  }
  
  initMapbox(element) {
    const markers = JSON.parse(element.dataset.pins)
    
    if (element) {
      mapboxgl.accessToken = element.dataset.key
      this.map = new mapboxgl.Map({
        container: element,
        style: 'mapbox://styles/mapbox/streets-v10',
        zoom: 5
      })
      
      markers.forEach((marker) => {
        this.addMarker(marker)
      })
      
      this.zoomToFit(markers)
    }
  }
  
  addMarker(marker) {
    new mapboxgl.Marker().setLngLat([marker.lng, marker.lat]).addTo(this.map)
  }
  
  zoomToFit(markers) {
    this.map.fitBounds(markers)
  }
}
