import { Controller } from "@hotwired/stimulus";
import EditorJS from "@editorjs/editorjs";

// These are the plugins
import CodeTool from "@editorjs/code";
import Header from "@editorjs/header";
import ImageTool from "@editorjs/image";
import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";

// Connects to data-controller="editor"
export default class extends Controller {
  static targets = ["page_body"];

  csrfToken() {
    const metaTag = document.querySelector("meta[name='csrf-token']");
    return metaTag ? metaTag.content : "";
  }
  connect() {
    console.log("Hello, Stimulus!", this.element);

    const initialBody = this.getInitialBody();

    this.contentEditor = new EditorJS({
      // Holder is the target element
      holder: this.page_bodyTarget,
      data: initialBody,
      tools: {
        header: {
          class: Header,
        },
        list: {
          class: List,
        },
        paragraph: {
          class: Paragraph,
          config: {
            inlineToolbar: true,
          },
        },
        code: CodeTool,
        image: {
          class: ImageTool,
          config: {
            endpoints: {
              byFile: `/admin/pages/upload_image`,
            },
            additionalRequestHeaders: {
              "X-CSRF-Token": this.csrfToken(),
            },
          },
        },
      },
    });

    this.element.addEventListener("submit", this.saveEditorData.bind(this));
  }

  getInitialBody() {
    const hiddenBodyField = this.element.querySelector("#page_body_hidden");
    console.log(hiddenBodyField.value)
    if (hiddenBodyField && hiddenBodyField.value) {
      return JSON.parse(hiddenBodyField.value);
    }
    return {};
  }

  async saveEditorData(event) {
    event.preventDefault();

    const outputData = await this.contentEditor.save();
    const pageForm = this.element;

    const hiddenInput = document.getElementById("page_body_hidden");

    hiddenInput.value = JSON.stringify(outputData);
    pageForm.submit();
  }
}