import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password-visibility"
export default class extends Controller {

  static targets = ['field']

  connect() {

  }

  swap(e) {
    if (this.fieldTarget.type === 'password') {
      this.fieldTarget.type = 'text'
    }
    else {
      this.fieldTarget.type = 'password'
    }
  }
}
