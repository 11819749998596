import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="day"
export default class extends Controller {
  connect() {
  }
  
  toggle() {
    let day = this.element
    let under = day.querySelector('.under')
    
    console.log(under)
    
    if (under) {
      under.remove()
      day.classList.remove('expanded')
    } else {
      const under = this.generateUnder()
      day.appendChild(under)
      day.classList.add('expanded')
    }
  }
  
  expand() {
    let day = this.element
    
    const under = this.generateUnder()
    day.appendChild(under)
    day.classList.add('expanded')
  }
  
  close() {
    let day = this.element
    let under = day.querySelector('.under')
    
    under.remove()
    day.classList.remove('expanded')
  }
  
  generateUnder() {
    const div = document.createElement('div')
    div.dataset.action = 'click->day#close'
    div.classList.add('under')
    return div
  }
}
