export function clearSection(container) {
  const descendents = container.getElementsByTagName('*')

  var i, e, d

  for (i = 0; i < descendents.length; ++i) {
    if (descendents[i].tagName.toLowerCase() == 'input'){
        switch (descendents[i].type){
            case 'text':
            case 'password':
            case 'color':
            case 'date':
            case 'email':
            case 'month':
            case 'number':
            case 'range':
            case 'search':
            case 'tel':
            case 'time':
            case 'url':
            case 'week':
                descendents[i].value = '';
                break;

            case 'radio':
            case 'checkbox':
                descendents[i].checked = false;
        }
    }
    else if (descendents[i].tagName.toLowerCase() == 'select'){
        descendents[i].selectedIndex = 0;
    }
  }
}